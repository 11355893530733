<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold">Hub</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <CardGrid :list="list" />
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import CardGrid from '@/components/common/CardGrid'

export default {
  name: 'Dashboard',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    CardGrid,
  },

  data() {
    return {
      list: [
        {
          title: 'FAQ',
          subtitle: 'Manage frequently asked questions',
          to: { name: 'hub.faqs' },
        },
        {
          title: 'Contact Directory',
          subtitle: 'Update contact directory',
          to: { name: 'hub.contact-directory' },
        },
      ],
    }
  },
}
</script>
